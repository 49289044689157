import React from 'react';
import './App.css';

const Header = () => {
  return (
    <header className="header">
      <h1>Evidencer Claim Management System</h1>
    </header>
  );
}
// Footer component
const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 Web3 Legal Engineering. All rights reserved.</p>
    </footer>
  );
}

const App = () => {
  return (
    <div className="container">
      <Header />

      <div className="grid">
        {/* Trunk */}
        {/* Trunk */}
        <div className="box trunk-container">
          <a href="https://freeweb3.com" className="branch trunk">
            <div className="img-container trunk-image"> {/* Add custom class "trunk-image" */}
              <img src="https://freeweb3.infura-ipfs.io/ipfs/QmVaC94oitTdBzeq2sK7598xK328MwsPAahPaqoU84ZXqc" alt="evidencer" />
              <div className="img-overlay">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmVaC94oitTdBzeq2sK7598xK328MwsPAahPaqoU84ZXqc" alt="evidencer" />
              </div>
            </div>
          </a>
        </div>


        {/* Video */}
        <div className="box video-container">
          <video controls style={{ width: '100%' }}>
            <source src="https://freeweb3.infura-ipfs.io/ipfs/QmPAT4NNdPew9XQ6NiVh8FNyFgrEa6swzZgyqAnsfTNqq7" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Main Branches */}
        <div className="grid-container">
          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmeJ9dsUVMGRvxyRPtXdnquXwNadHzfB5u3ANcfkERTFeh" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmeXeUirWCB84ow9tvjTEUtXtZurua8LgRmCBKZBCNnvXt" alt="Main Branch 1" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmemZtGNxqnqGbXJrYxL1mV3Q9pUtsNz7KPY8n5rc3mfWG" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmWiqY1pvo3oxs4X8PpzaYsmFZNnx41TyucuiDTaqdbpv7" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmPgbDB5cs3v88KgbaE7E4mmoa82oGznS6n1PTHyQcFjQv" alt="Main Branch 2" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmeBvda8hiSmjM5hNpumRRjssgJ8Rs5oQrcfTY8y94NQrz" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmZTW65PNa5SEX7ke3ZpswMeKPaC8HTqfCNsqGDucf7uCH" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmckLm8KmN7UoiH4N1o56XAYesV4BjYQWZqjaix3hghqwi" alt="Main Branch 3" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmeVDQMYn5XRCSkmu2iYfcMJb12BBJ6v7GiR76CMdeghwk" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmfB9GMT4H4hbFrfB3jejyrTs41Ks6C2TuCZGbApHNkRSg" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmZa1w7GNLxN66Zk2qDzHTvfmyNWQiYcv98jn8VHD3HfNe" alt="Sub-Branch 1.1" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmNvsv8TeQ18yD6ogzKQe8Qj5nKSUSyXBTQPhyEXqNnzFT" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmeandRv1vZgyQCWnczuads5ScXNa6NZe5ZhMDyjcDiymJ" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmPPL3cymygm4JXyJqGMQCwzkpVQVT4t5KLqhhCiPrCur2" alt="Sub-Branch 1.2" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmbZYQb1sHrwUY2s533W5EZYN34C3eWvERUB7dAo9XCZ2b" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmY7SfVBnQnhL3ET6ng6yem46D6MvChHPzEkktnHN5fta9" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmZvkC9RW7pDEc8Jih7jEyV57NfjLNJVobHRfxeT3Lz5Us" alt="Sub-Branch 1.3" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/Qmcj9EVc2PPxBfcBioZyzt8757Nw62ZdMM4dNFnXbNR4dD" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmeYB4JUWg1VebAWb3iYMCunxhu18kfdoDNVfmHn6v6nSf" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmU4zFErwUfFWRgys8XUWRZm7H3wnnvQxB2BpvXvTovRBB" alt="Sub-Branch 2.1" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmcjruQYDrtzb2p774eW3yUkfdUpNMSxSrLgRTX2FZGPSu" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmYUdKTRbfLz6mCfWvxypVncwneaFVZByeXPaa2tmhtCYw" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmUMitUghXX5z9HankjWBtKBgHgNkiWKpufQn3c6pek5M9" alt="Sub-Branch 2.2" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmYgLQBKNoTWGaVo72NLFQQWKQedizXnnrrMmvvtnMdUHB" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmNpBuig2QGA4fnjnG3wtd24eTfSGaZUyKhdxRdBxoz4m8" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmeMDUVtbeEtBet89YUnBbgqmfg6cScZeZAdjJvUKmJemh" alt="Sub-Branch 2.3" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmYQYxhoDU2ZsTwkfJbVFNjk8FsH2y8EyLLsYApvvw6PLH" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmTRwPNPoC3pe1VaYCDVZLcAxzpbb8SGTctdM6CekNrhc5" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmSfciBxXjywdHFEa5P2Z7oqZoU4VhbUpmiKcwyph2Qd5A" alt="Sub-Branch 3.1" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmPXFtNkhwh19LoUFaCJ6a4bY8PMFhocHtyUYfkSX6q7ja" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmWtiSS18vKpw79Mt8du8LA56aWn4bReWASnvsE7VxyyTP" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmP2nC2Eto4E3ZHdqzjtYnPNeZNsroQqx1Z3kAWtwEQ3SK" alt="Sub-Branch 3.2" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmUoyYfrSzzBCfH2hfBwSAPF3qEbr3kFUYzSZ2qsDAEUoo" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>

          <div className="branch">
            <a href="https://freeweb3.infura-ipfs.io/ipfs/QmdnXRbDfZdLhFRbYJQcouHAuvYZjuboMtjRr7maVyTWsb" target="_blank" rel="noreferrer" className="sub-branch">
              <div className="img-container">
                <img src="https://freeweb3.infura-ipfs.io/ipfs/QmTnLw5A58ztWgEo8H3tcE7qAtVQxPAP9ncAVFRzJ327oj" alt="Sub-Branch 3.3" />
                <div className="img-overlay">
                  <img src="https://freeweb3.infura-ipfs.io/ipfs/QmSezRiH8TBBdN1QfT8JJP76dBmJe1LPgMVjsqY5o672bd" alt="Evidencer Form" />
                </div>
              </div>
            </a>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default App;
